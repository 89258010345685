.company-peoples-main-container :global{
    .header-container{
        display: flex;
        gap: 2px;
        .header-contents{
            display: flex;
            justify-content:space-between;
            width: 100%;
            .contents{
                display: flex;
                gap: 10px;
                align-items: center;
                .header-first-btn{
                    background: #f1f1fb;
                    color: #707c97;
                    box-shadow: none;
                    font-size: 12px;
                    text-transform: capitalize;
                    padding: 1px 10px;
                    height: 30px;
                }
            }
        }
      
    }
    .body-container{
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        .company-people-card{
            display: flex;
            gap: 2px;
            width: 100%;
       
        .body-contents{
            display: flex;
            justify-content:space-between;
            width: 100%;
            .contents{
                width: 100%;
                display: flex;
                flex-direction: column;
                .comapny-card{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    border: 1px solid rgba(0,0,0,0.2);
                    width: 100%;
                    padding: 10px;
                    border-radius: 5px;
                    .comapny-header{
                        display: flex;
                        gap: 10px;
                        align-items: flex-start;
                        .company-name-item{
                            margin: 0;
                        }
    
                    }
                    .comapny-details{
                        display: flex;
                        justify-content: space-evenly;
                    }
                }
    
            }
    
        }
    }
    }
    

}