.introduce-message-header-main-container :global{
    border: none;
    display: flex;
    .accordianHeading {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 600;
    }
}

.answer-behave-body-main-container :global{
    .body-content-container{
        margin-top: 5px;
        .sub-header{
            padding: 10px 0px;
            font-weight: 500;
            font-size: 15px;
        }
        .contents{
            .answer-body{
                line-height: 20px;
                font-size: 12px;
                font-weight: 400;
                color: rgb(109, 107, 107);
                font-size: 14px;
            }
        }
    }
}

.introduce-message-tab :global{
    .introduce-tab{
        .tabs-headers{
            .heading{
                font-size: 14px;
                text-transform: capitalize;
                font-weight: bold;
                // color: #a84acb;
            }

        }
    }
    .tab-body{
        .tab-body-container{
            // border: 1px solid red;
            // height: 100%;
            min-height: 500px;
            display: flex;
            .container{
                // border: 1px solid green;
                height: 100%;
                width: 50%;
               
                min-height: 500px;
            }
            .container1{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding-top: 110px;
                padding-bottom: 30px;
                .editor-parent-container{
                    display: flex;
                    flex-direction: column;;
                    align-items: center;
                    gap: 20px;
                    .editor-container{
                        display: flex;
                        gap: 10px;

                    }
                    .btn-container{
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;
                    }


                }
            }
            .container2{
                padding: 10px;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                .container2-sub-container{
                    width: 70%;
                    .container2-box{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .container2-header{
                            font-weight: 600;
                            font-size: 15px;
                        }
                    }

                    .sub-heading-container{
                        .sub-heading{
                            font-size: 14px;
                            color: gray;
                        }
                    }
                }                
            }
        }

    }
}