.filterMainComponent :global{
    padding: 10px;

}


.filterClassComponentMainComponent{
    min-width: 500px;
    padding-bottom: 0px !important;
    // border: 1px solid green;
}

.messageBoxMainContainer{
    
}