.chatboat-answer-type-body-main-container :global{
    .answerBody-container{
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap:20px;
        .body-box{ 
            .contents{
                display: flex;
                // justify-content: ;
                align-items: center;
                gap: 15px;
                .answer-heading{
                    font-weight: 500;
                    font-size: 16px;
                    color: #222;
                }
                .answer-body{
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #222;
                    font-size: 14px;
                }
                .external-content{
                    
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .desc{
                        font-size: 14px;
                        color: #a84acb;
                        cursor: pointer;
                        &:hover{
                            text-decoration: underline;
                            color: #8939a7;
                        }
                    }
                    .menu-book-icon{
                        font-size: 18px;
                        color: #a84acb;
                    }


                }
            }
           
        
         } 
    }
 
}


.chatboat-answer-type-header-main-container :global{
        border: none;
        display: flex;
        .accordianHeading {
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 15px;
          font-weight: 600;
        }
}