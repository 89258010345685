.details-main-component :global {
  padding: 10px;
  .notification-devices{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding:20px 100px 20px 20px;
    .header{
      font-size: 13px;
    }
    .divices-checkbox{
      display: flex;
      gap: 50px;
      .form-box-for-checkbox{
        .MuiFormControlLabel-label{
          font-size: 12px;
          font-weight: bold;
        }

      }
    }
  }
  .browser-notifications{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding:20px 100px 20px 20px;
    .heading{
      font-size: 13px;
      font-weight: bold;

    }
    .sub-heading{
      font-size: 13px;
    }
    .notifications-checkbox{
      display: flex;
      flex-direction: column;
      .form-box-for-checkbox{
        height: 30px;
        .MuiFormControlLabel-label{
          font-size: 12px;
        }

      }
    }

  }
}

.details-toolbar-component :global {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .toolbarContent {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }
}
