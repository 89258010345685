.menubarCommonMainClass :global{
    .MuiMenu-paper{
        background-color: green;
    }
   ul{
    padding-top: 0;
   }
}

.filterSelected{
    background: #c166e2 !important;
    color: white !important;
}
.filterUnSelected{
    background: white !important;
    color: black !important;
}
.deletedIcon{
    color: gray !important;
    svg{
        &.deletedIconClass{
            display: none !important;
        }

    }
}