.pullseInputBoxContainer{
    padding: 1px 2px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px;
   
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: none !important;
   
    &.mouseEnterd {
        border:1px solid #a84acb;
    }
    &.focused{
       border : 1px solid #a84acb;
       box-shadow:  0 0 0 3px #f8e6ff !important;
    }

    &.error{
        border : 1px solid #cb4a4a;
        box-shadow:  0 0 0 3px #ffe6e6 !important;
    }
    
    .pullse-input-box{
        box-shadow: none;
        margin-left: 1;
        flex: 1;
        font-size: 14px;
        &::placeholder{
            color: #a84acb !important;
            font-size: 9px !important;

        }
    }
}