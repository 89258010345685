.details-main-component :global {
  padding: 10px;
  .detail-header {
    // border: 1px solid green;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .setup-two-factor {
      background: #f1f1fb;
      color: #707c97;
      height: 30px;
      box-shadow: none;
      font-size: 12px;
      text-transform: capitalize;
      padding: 3px 10px;
    }
    .change-password {
      text-transform: capitalize;
      background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
      color: white;
      height: 26px;
      box-shadow: none;
      font-size: 12px;
    }
  }
  .detail-com-form-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 30px;
    .input-box-component {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 40%;
      .input-label {
        font-size: 13px;
      }
      .input-box {
        ::placeholder {
          font-size: 12px;
        }
      }
    }
    .input-action-component {
        display: flex;
        gap: 10px   ;
      .save-changes {
        text-transform: capitalize;
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        color: white;
        height: 26px;
        box-shadow: none;
        font-size: 12px;
      }
    }
  }
  .profile-picture-upload {
    margin-top: 10px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .heading {
      font-weight: 600;
      font-size: 20px;
    }
    .image-component {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background-color: #ddd;
    }
    .upload-image {
      // margin-top: 20px;
      text-transform: capitalize;
      background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
      color: white;
      height: 26px;
      box-shadow: none;
      font-size: 12px;
      width: 150px;
    }
    .content {
      color: #707c97;
      font-family: Poppins;
      font-size: 12px;
    //   font-style: normal;
      width: 450px;
    }
  }
}

.details-toolbar-component :global {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .toolbarContent {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    .filter-buttom {
      font-size: 15px;
      padding: 0;
      text-transform: capitalize;
      background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .users-header {
      font-size: 15px;
      padding: 0;
      text-transform: capitalize;
      color: gray;
    }
    .add-profile {
      background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
      color: white;
      // height: 20px;
      padding: 1px 10px;
    }
  }
}
