@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
body{
    margin: 0;
    padding: 0;
    font-family:Poppins ;
    ::-webkit-scrollbar-thumb {
        background-color:#dadee9;
        /* background: ; */
        border-radius: 10px;
      }

      ::-webkit-scrollbar {
        width: 6px;
        /* height: 79px; Set the width of the scrollbar */
      }
}
/* #a84acb */