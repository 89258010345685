code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	text-shadow: 0px 1px rgba(0,0,0,0.3);
	font-family: Consolas, Monaco, 'Andale Mono', monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;	
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #272822;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: slategray;
}

.token.punctuation {
	color: #f8f8f2;
}

.namespace {
	opacity: .7;
}

.token.property,
.token.tag {
	color: #f92672;
}

.token.boolean,
.token.number{
	color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string {
	color: #a6e22e;
}

.token.operator{
    background-color: inherit;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
	color: #f8f8f2;
}

.token.atrule,
.token.attr-value
{
	color: #e6db74;
}


.token.keyword{
color: #f92672;
}

.token.regex,
.token.important {
	color: #fd971f;
}

.token.important {
	font-weight: bold;
}

.token.entity {
	cursor: help;
}