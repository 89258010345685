.users-table-main-component :global{
    overflow: scroll;
    padding: 10px 10px;
    height: 100%;
    // overflow: hidden;
    .table-main-container{       
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }
    .table-container{
        .table-header{
            .table-header-row{
                background-color: #F6F6F6;
                .header-row-items{
                    font-weight: bold;
                    color: #222;
                }
            }
        }
    }

}