.mainInboxComponentClass :global {
  min-width: 400px !important;
  padding-right: 0;
   


}
.inboxSideToolbar :global{
  background-color: #f4f4fb  !important;
  // padding: 5px;
  padding: 5px 2px 0px 4px;
      
  .sideToolbarCard{
    // border: 3px solid green;
    // border: 1px solid green;
    padding: 2px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

}

.inboxSecondComponent :global {
    height: 100vh;
    width: 100%;
    max-width: 70%;
    background-color: #f4f4fb !important;
    padding-bottom: 0 !important;
    // background: white !important;
    overflow: hidden !important;
    padding: 5px;
    .mainComponentClass{
      background-color: white !important;
      // box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
      // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
      border-radius: 10px;
      padding: 5px;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
      // box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
    ::-webkit-scrollbar{
      display: none;
    }
    // padding-left: 0 !important;
    // border: 2px solid red  !important;
    // padding-bottom: 0px !important;
    // padding-right: 0px !important ;

}

.commonAccordionClass  {
  border: none;
  outline: none;
  box-shadow: none !important;
  &.commonAccordionClass::before{
    position: inherit;
  }

}

.inboxSideToolbarCard1{
  background-color: #f4f4fb  !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  &.commonAccordionClass::before{
    position: inherit;
  }
  border-radius: 10px;
  border: 1px solid  #f2dff9 !important;
  // box-shadow:  0 0 0 3px #f8e6ff !important;
  
}