.sidebarpanleMainComponent :global {
  .listItemButtonsClass {
    position: relative;
    padding-top: 1;
    padding-bottom: 1;
    .toogleKey {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background: #a84acb;
      box-shadow: 2px 3px 7px #a84acb;
      border-radius: 35px;
    }
    .listItemForCollaspeHeading {
      .MuiListItemText-primary {
        font-weight: 700;
      }
    }
    .listIconContainer {
      justify-content: center;
    }
    .itemIcons {
      font-size: 20px;
    }
    .itemText {
      display: flex;
      justify-content: space-between;
      .MuiListItemText-secondary {
        font-weight: 700;
        color: #707c97;
      }
      .MuiListItemText-primary {
        color: #707c97;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
    }
    &.selectedItemContainer {
      .itemIcons {
        color: #a84acb;
      }
      .itemText {
        .MuiListItemText-primary {
          color: #a84acb;
        }
        .MuiListItemText-secondary {
          color: #a84acb;
        }
      }
    }
  }
  .headerContainer {
    align-items: center;
    .icon {
      font-size: 20px;
      color: #707c97;
      cursor: pointer;
    }
  }
}
