.details-main-component :global {
  padding: 10px;
  .detail-com-form-box {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // justify-content: center;
    padding: 30px;
    gap: 20px;
    padding-left: 30px;
    .input-box-component {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 40%;
      min-width: 40%;
      .input-label {
        font-size: 13px;
      }
      .input-box {
        ::placeholder {
          font-size: 12px;
        }
      }
      .form-box-for-checkbox{
        height: 40px;
        .MuiFormControlLabel-label{
          font-size: 12px;
        }

      }
    }
    .input-action-component {
      .save-changes {
        text-transform: capitalize;
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        color: white;
        height: 26px;
        box-shadow: none;
        font-size: 12px;
      }
    }
  }
}

.details-toolbar-component :global {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .toolbarContent {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }
}
