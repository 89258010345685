.sidebarMainClass :global {
  .mainDrawer {
    .MuiDrawer-paper{
        &.MuiDrawer-paperAnchorLeft{
            border: none;
        }

    }
    border: none;
    .itemsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
    .toolbarContant{
        justify-content: center;
        align-items: center;
    }
    .iconMaonBoxContainer{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
    .bottomIcons{
      gap: 0 !important;
    }
    .sidebarIconContainer {
        overflow: hidden;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 29px;
      .sideIconListItem {
        .sideIconbutton{
            justify-content: center;
            .sideIcon{
                justify-content: center;
            }
        }
       
      }
    }
  }
  .secondDrawer{
    // transition: all 0.3s;
    border-left: 2px solid #f6f5fc;
  }
}

.ticketCardContainer :global{
  // max-width: 400px;
  // overflow: hidden;
.ticketCardsMainClass{
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px !important;
  border-radius: 5px ;
  position: relative;
  // &::before{
  //   content: '';
  //   position: absolute;
  //   border-bottom: 2px solid #a84acb;
  //   border-left: 2px solid #a84acb;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 10%;
  //   // border: 1px solid green;
  //   border-radius: 5px 0px 0px 0px;

  // }
  // &::after{
  //   content: '';
  //   position: absolute;
  //   border-top: 2px solid #a84acb;
  //   border-right: 2px solid #a84acb;
  //   top: 0;
  //   right: 0;
  //   height: 100%;
  //   width: 10%;
  //   // border: 1px solid green;
  //   border-radius: 0px 0px 5px 0px;
  // }
  .displayUserLastMessage{
    max-width: 400px;
    max-height: 100px;
    
    width: 100%;
    display: inline-block;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    .messageContainer{
      display: flex;
      overflow: hidden;
      width: 100%;
      hr{
        height: 1px;
        background-color: white;
        border: none;
      }
      // display: inline-block;
    }
    span{
    
        p{
          margin: 0;
          // display: inline-block;
          padding: 3px;
          img{
            height: 20px;
          }
        }
      
    }
   
  }

}
}