.ql-mention-list-container {
    width: 270px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    z-index: 9001;
    overflow: auto;
  }
  
  .ql-mention-loading {
    line-height: 44px;
    padding: 0 20px;
    vertical-align: middle;
    font-size: 16px;
  }
  
  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .ql-mention-list-item {
    cursor: pointer;
    line-height: 44px;
    font-size: 16px;
    padding: 0 20px;
    vertical-align: middle;
  }
  
  .ql-mention-list-item.disabled {
    cursor: auto;
  }
  
  .ql-mention-list-item.selected {
    background-color: #d3e1eb !important;
    /* background-color: #a84acb; */
    /* color: white; */
    text-decoration: none;
  }
  
  .mention[data-denotation-char='#']{
    background-color: gray !important;

  }
  .ql-toolbar.ql-snow{

    /* background-color:#d3e1eb ; */
    padding: 2px !important;
    font-size: 10px;
    border: none  !important;

  }
  .quill  .ql-container.ql-snow #emoji-palette{
    top: 20px  !important;
  }
  .quill  .ql-container.ql-snow  .ql-tooltip{
    left: 0px !important;
  }
  .ql-toolbar.ql-snow .ql-color-picker .ql-picker-options{
    position: absolute !important;
    top: 0px !important;
  }
  .ql-toolbar.ql-snow .ql-formats button{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: transparent !important ;
    box-shadow: 0px 0px 0px 3px #f8e6ff;
    /* background-color: #a84acb; */
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .ql-toolbar.ql-snow .ql-formats .ql-color-picker .ql-picker-label{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    /* background-color: #d3e1eb ; */
    background-color: transparent !important ;
    box-shadow: 0px 0px 0px 3px #f8e6ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mention {
    height: 30px;
    width: 65px;
    margin: 5px 0px;
    border-radius: 6px;
    background-color: #6f6f6f !important;
    color: white !important;
    padding: 3px 0;
    margin-right: 2px;
    user-select: all;
    line-height: 28px;
  }
  
  .mention > span {
    margin: 0 3px;
    /* white-space: pre; */
  }