.mentionCssClass :global {
    width: 300px;
    // border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    border: 1px solid #a84acb;
    box-shadow: 0px 0px 0px 3px #f8e6ff !important;
    padding: 10px;
    height: 200px;
    .quill{
        height: auto;
        border: none;
        .ql-container{
            border: none !important;
            height: auto;
            .ql-editor{
                height: 150px;
            }
        }
        display: flex;
        flex-direction: column-reverse;
    }
    
}