.mainMessageBox :global{
    .messageCom{
       display: inline-block;
       max-width: 300px;
        p{
            margin: 0;
            font-size: 14px;
            img{
                height: 100px;
            }
        }
    }

}