.userprofileBody :global {
    // background: white !important;
    padding: 10px;
  //   .contentContainer {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 5px;
  //   }
  .user-details-container{
    gap: 10px;
    display: flex;
    flex-direction: column;
    .user-display-detail-card{
        border: 1px solid rgba(0,0,0,0.2);
        // height: 400px;
        border-radius: 5px;
        padding: 10px;
        &.user-card{
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        &.notes-card{
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 15px;

        }
      
    }
   

  }
  .user-conversations{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .conversaton-card{
        border: 1px solid rgba(0,0,0,0.2);
        // height: 400px;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        cursor: pointer;
        &:hover{
          background-color: #fbefff;
        }
        
    }
    
  }
  .user-envents{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .event-card{
        border: 1px solid rgba(0,0,0,0.2);
        // height: 400px;
        border-radius: 5px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

    }

  }


  }
  
  .userprofileToolbarComponent :global {
    width: 100%;
    .toolbarContent {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      .userDetails{
        display: flex;
        align-items: center;
        gap: 10px;
        .user-name{
            font-weight: bold;
            text-transform: capitalize;
        }
        .user-lable{
            border-radius: 5px;
            color: gray;
            height: 25px;
        }
        .user-location{
            text-transform: capitalize;
            color: gray;
        }
      }
    //   .filter-buttom {
    //     font-size: 15px;
    //     padding: 0;
    //     text-transform: capitalize;
    //     background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
    //     background-clip: text;
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //   }
    //   .users-header{
    //       font-size: 15px;
    //       padding: 0;
    //       text-transform: capitalize;
    //       color: gray;
  
    //   }
      .add-profile {
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        color: white;
        // height: 20px;
        padding: 1px 10px;
      }
    }
  }
  