.mentionCssClass :global {
    width: 100%;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    
    // height: 200px;
    .quill{
        height: 130px;
        border: none;
        .ql-container{
            border: none !important;
            .ql-editor{
                // border: 2px solid green;
                max-height: 100px;
            }
        }
        display: flex;
        flex-direction: column-reverse;
    }
    
}