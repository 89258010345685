.createProfileToolbarMainClass :global{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content{
        display: flex;
        gap: 10px;
        align-items: center;
        .draftChip{
            border-radius: 5px;
            padding: 0px 5px;
            height: 25px;
            &.activeChip{
                background-color: #8F1EB3;
                color: white;
            }
            
         
        }

    }
    .toolbarContent{
        display: flex;
        gap: 15px;
        .displaynote{
            background:#f0f2fa;
            color:gray;
            &:hover{
                background: #f0f2fa;
            }

        }
        .add-profile{
            background:linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%),

        }
    }


}