.customAccordianMainClass :global {
  .accordianContainer {
    width: 100%;
    padding: 5px;
    &.activeAccordian {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
    box-shadow: none;
    outline: none;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    &::before {
      display: none;
    }
  }
}
