.messageTextAreaClass {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border: none !important;
    resize: none;
    padding: 15px 0px 0px 10px;
    font-size: 14px;
    background:transparent;
    font-family:sans-serif ;
    color: black;
    font-weight: 300;
    // &::placeholder{
    //     padding-top: 10px;
    // }

}

.sendMessageClass :global{
    justify-content: flex-end;
    align-self: flex-end;
    .cardContent{
        background:#9b55b6;
        color: white;
    }
    .messageTimeClass{
        align-self: flex-end;
            
    }

}

.reciverMessageClass :global{
    justify-content: flex-start;
    .cardContent{
        background:  "#f2f2f2";
        
    }

}

.hoverClassForChatboatHeader{
    cursor: pointer;
    &:hover{
        background: rgb(231, 231, 231);
    }
}
.showHeaderDetailsClass :global {
    height: 30%;
    .headerContent{
        width: 100%;
        flex-direction: column;
        justify-content: center;

    }

}