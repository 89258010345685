.hanover-workflow-header-main-container :global{
    border: none;
    display: flex;
    .accordianHeading {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 600;
    }
}


.handover-workflow-body-main-container :global{
  .body-content-container{
      margin-top: 5px;
      .sub-header{
          padding: 10px 0px;
          font-weight: 500;
          font-size: 14px;
      }
     
  }
}

