.profileAutomationMainContainer :global{
    // background: white !important;
    padding: 10px;
}

.profileToolbarMainClass :global{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .toolbarContent{
        display: flex;
        gap: 15px;
        .displaynote{
            background:#f0f2fa;
            color:gray;
            &:hover{
                background: #f0f2fa;
            }

        }
        .add-profile{
            background:linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%),

        }
    }

}