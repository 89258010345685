.listComponentMainContainer :global{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .listItemContainer{
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 5px;
        
    }

}