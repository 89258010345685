.sendingDropDownMainContainer :global{
    .selectBoxOfSendingDropdown{
        #demo-select-small{
            display: flex !important;
            gap: 5px;
            align-items: flex-end;
            .dropdownMessage{
                color: gray;
                font-size: 12px;
            }
        }
        .menuItems{
            display: flex;
            gap: 5px;
        }

        
       
    }
    fieldset{
        border: none !important;
        outline: none;
    }

}