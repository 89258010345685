.chatboat-answer-type-header-main-container :global{
    border: none;
    display: flex;
    .accordianHeading {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 600;
    }
}

.channels-body-main-container :global{
    .body-content-container{
        .channel-headers{
            display: flex;
            flex-direction: column;
            gap:20px;
            .sub-heading{
                font-size: 14px;
                color: gray;
                font-weight: 500;
            }
            .channel-heading{
                font-weight: 700;
                font-size: 16px;

            }

        }
        .channel-app-container{
            display: flex;
            gap: 20px;
            margin-top: 20px;
            .apps{
                .app-heading{
                    font-weight: 800;
                }
                .app-box{
                    padding: 8px 2px;
                    display: flex;
                    gap:10px;
                    align-items: center;
                    .app-name{
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
            }

        }
        .channel-audiance{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .channel-audiance-container{

                .channel-audiance-heading{
                    font-weight: 800;
                }
                .channel-audiance-sub-heading{
                    font-size: 14px;
                    color: gray;
                }
                .audience-menu-items{
                    border: 1px solid green !important;
                    color: red;
                    // display: flex;
                    // gap:20px
                    
                }
                .audience-button{
                    .audience{
                        background: rgba(241,241,241);
                        font-size: 13px;
                        text-transform: capitalize;
                        padding: 5px 15px;
                        color: #222;
                        font-weight: 700;
                    }
                    // #basic-menu{
                        
                        
                    // }
                    // .audience-menu-container{
                    //     border: 1px solid red;
                       
                    //     border: 1px solid red !important;
                   
    
                    // }
                    
                    
                }
                

            }

        }

    }

}

.audiance-accordian-table :global{
    margin-top: 10px;
    padding: 0;
    .accordianContainer {
        // padding:;
        // height: 40px;
        width: 100%;
        padding:  0px;
        &.activeAccordian {
          border: none !important;
          box-shadow: none !important;
        }
        box-shadow: none;
        outline: none;
        border: none transparent;
        &:hover {
          border: none !important
        }
        &::before {
          display: none;
        }
      }
}