.tag-main-component :global {
    // background: white !important;
    padding: 10px;
  //   .contentContainer {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 5px;
  //   }
  .body-headers-tool{
      // border: 1px solid green;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .body-header-container{
          display: flex;
          gap: 10px;
          .global-btn{
              background: transparent !important;
              padding: 0;
              span{
                  margin: 0;
              }
              min-width: 30px;
          }
      
          .header-first-btn{
              background: #f1f1fb;
              color: #707c97;
              box-shadow: none;
              text-transform: capitalize;
              padding: 3px 10px;
          }
          display: flex;
          align-items: center;
          .display-total-users{
              font-weight: bold;
          }
  
          &.body-headers-2{
              .header-first-btn{
                  padding: 3px 0px;
                  min-width: 35px;
                  span{
                      margin: 0;
                  }
                  // background: red;
                 
              }
  
          }
      }
  }
  }
  
  .tag-toolbar-component :global {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .toolbarContent {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        width: 100%;
      .filter-buttom {
        font-size: 15px;
        padding: 0;
        text-transform: capitalize;
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .users-header{
          font-size: 15px;
          padding: 0;
          text-transform: capitalize;
          color: gray;
  
      }
      .add-profile {
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        color: white;
        // height: 20px;
        padding: 1px 10px;
      }
    }
  }
  