.company-data-modelling-component :global {
    // background: white !important;
    padding: 10px;
    .data-modelling-dropdown{
        // border: 1px solid green;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        .create-data-modelling-btn{
            text-transform: capitalize;
            
        }
    }


  }
  
  .allUsersToolabrMainComponent :global {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .toolbarContent {
      display: flex;
      gap: 30px;
      .filter-buttom {
        font-size: 15px;
        padding: 0;
        text-transform: capitalize;
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .users-header{
          font-size: 15px;
          padding: 0;
          text-transform: capitalize;
          color: gray;
  
      }
      .add-profile {
        background: linear-gradient(137deg, #8f1eb3 22.4%, #3f1599 101.81%);
        color: white;
        // height: 20px;
        padding: 1px 10px;
      }
    }
  }
  