.answer-behave-header-main-container :global{
    border: none;
    display: flex;
    .accordianHeading {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 600;
    }
}
.answer-behave-body-main-container :global{
    
    .body-content-container{
        margin-top: 20px;
        .answer-behave-heading{
            font-size: 14px;
            color: #222;
        }
        .selection-main-container{
            // margin: 5px 0px;
            .selection-form-lable{
                .radio-box{

                }
                .radio-lable{
                    font-size: 14px;
                }

            }

        }
      

    }

}


.answerMultipleQuestions :global{
    width: 50%;
    padding: 0px 10px;
    .heading-container{
        .header{
            font-size: 14px;
            font-weight: 400;
        }
        .sub-header{
            padding: 10px 0px;
            font-weight: bold;
            font-size: 14px;
        }
        .body-content{
            display: flex;
            margin: 10px 0px;
            align-items: center;
            gap: 10px;

            .heading{
                font-size: 14px;
                color: #222;
                word-spacing: 2px;
            }

        }
        .editor-container{
            display: flex;
            margin: 10px 0px;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            gap: 15px;

        }

    }

}