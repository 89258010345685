.allUsersBodyMainComponent :global {
    // background: white !important;
    padding: 10px;
  //   .contentContainer {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 5px;
  //   }
  .body-headers-tool{
      // border: 1px solid green;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .body-header-container{
          display: flex;
          gap: 10px;
          .global-btn{
              background: transparent !important;
              padding: 0;
              span{
                  margin: 0;
              }
              min-width: 30px;
          }
      
          .header-first-btn{
              background: #f1f1fb;
              color: #707c97;
              box-shadow: none;
              text-transform: capitalize;
              padding: 3px 10px;
          }
          display: flex;
          align-items: center;
          .display-total-users{
              font-weight: bold;
          }
  
          &.body-headers-2{
              .header-first-btn{
                  padding: 3px 0px;
                  min-width: 35px;
                  span{
                      margin: 0;
                  }
                  // background: red;
                 
              }
  
          }
      }
  }
  }
  
  .allUsersToolabrMainComponent :global {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .toolbarContent {
      display: flex;
      gap: 30px;
    }
  }
  

.emailTempleteEditorClass :global{
    width: 100%;
    height: 300px;
    min-height: 300px;
    max-height: 300px;
 .quill{
    .ql-container{
        // height: inherit !important;
        height: 85% !important;
        .ql-editor{
            height: inherit !important;
        }
        &.ql-snow{
            #emoji-palette{
                top: 10px !important;
            }
        }
    }
        height: 100% !important;

 }
}
    
.emailTempleteEditorSubjectClass :global{
    width: 100%;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
 .quill{
    .ql-container{
        // height: inherit !important;
        height: 65% !important;
        .ql-editor{
            height: inherit !important;
        }
        &.ql-snow{
            #emoji-palette{
                top: 40px !important;
            }
        }
       
    }
        height: 100% !important;

 }

}
