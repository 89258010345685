.loginRawMainClass  :global {
    position: relative;
    .circle{
        display: inline-block;
        height: 1000px;
        width: 1000px;
        background: linear-gradient(269deg, #3E1599 1.06%, #8D1EB2 98.89%);
        border-radius: 50%;
        position: absolute;
        left: -80%;
        top: -115px;
        .innerCircle{
            display: inline-block;
            height: 600px;
            width: 600px;
            background: linear-gradient(90deg, #3E1599 -18.93%, #8D1EB2 99.96%);

            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

        }
    }

}