    .audiance-table-header-main-container :global{
    border: none;
    display: flex;
    height: 10px;
    padding: 0 !important;
    min-height: 30px !important;
    // border: 1px solid green !important;

    .accordianHeading {
        &:hover{
            color: #8F1EB3;
        }
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 600;
    }
    .accordianIcon{
        font-size: 18px;
    }
}

.audiance-table-body-main-container :global{
    padding: 0 0px;
    .body-heading{
        font-size: 13px;
        font-weight: 500;
    }

}


.audiance-table-container :global{

    padding: 0;
    box-shadow: none;
}
